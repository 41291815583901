<template>
  <section class="banner-box">
    <el-carousel height="500px">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <img class="banner-img" :src="item.img" alt="">
      </el-carousel-item>
    </el-carousel>
  </section>
  <section class="company-introduce usually-box">
    <h3 class="title">{{introduce.name}}</h3>
    <div class="company-text">
      <img :src="introduce.img" alt="">
      <p v-html="introduce.content"></p>
    </div>
  </section>
  <section class="company-dream usually-box">
    <h3 class="title">{{dreamList.name}}</h3>
    <ul class="flex-between" style="flex-wrap: wrap">
      <li class="flex-center active-border" v-for="(item, index) in dreamList.zqTextImgList" :key="index">
        <h3>{{item.name}}</h3>
        <p>{{item.content}}</p>
      </li>
    </ul>
  </section>
  <section class="service-box usually-box">
    <h3 class="title">{{serviceList.name}}</h3>
    <ul>
      <li v-for="(item, index) in serviceList.zqTextImgList" :key="index">
        <div class="service-img-box">
          <img class="product-img active-img" :src="item.img" alt="">
          <img class="product-img hide-img" :src="item.url" alt="">
        </div>
        <div class="servide-info">
          <h3>{{item.name}}</h3>
          <p>{{item.content}}</p>
        </div>
      </li>
    </ul>
  </section>
  <section class="history usually-box">
    <h3 class="title">{{historyData.name}}</h3>
    <el-card style="padding: 30px; font-size: 16px;">
      <ul class="year-list">
        <li v-for="(item, index) in yearList" :key="index" :class="{'on':yearTabs==item}" @click="yearTabs = item">
          <div>
            {{ item }}
          </div>
        </li>
      </ul>
      <el-timeline>
        <el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.showTime">
          {{ activity.name }}
        </el-timeline-item>
      </el-timeline>
    </el-card>
  </section>
  <section class="business usually-box">
    <h3>业务咨询 {{websiteMsg.mobile}}</h3>
    <h3>1V1按需求定制个性化方案，全程360°一站式服务</h3>
    <el-button plain @click="toPage('/post/contact')">立即咨询 <i class="el-icon-upload el-icon--right"></i></el-button>
  </section>
</template>
<script setup>
import { ref, reactive, computed } from 'vue'
import { MoreFilled } from '@element-plus/icons-vue'
import { getPageBaseAboutUsVo } from '@/api/about.js'
import { useRouter } from 'vue-router'
import { useWebsite } from '@/pinia/index'
let { websiteMsg } = useWebsite()
const router = useRouter()
function toPage(url) {
  router.push(url)
}
const bannerList = [
  {
    img: require("@/assets/about/back.png"),
  }
]
const yearTabs = ref(2022)

const yearModule = reactive({
  yearTabs: 2018,
  yearList: [
    {
      name: '2022'
    },
    {
      name: '2021'
    },
    {
      name: '2020'
    },
    {
      name: '2019'
    },
    {
      name: '2018'
    },
  ],
  activities: [
    {
      content: '知谦科技入选湖南省科技厅小巨人企业',
      timestamp: '2018-04-12 20:46',
      color: '#0766E1',
    },
    {
      content: '知谦科技入选湖南省科技厅小巨人企业',
      timestamp: '2018-04-03 20:46',
      color: '#0766E1',
    },

    {
      content: '知谦科技入选湖南省科技厅小巨人企业',
      timestamp: '2018-04-03 20:46',
      color: '#0766E1',
    },

    {
      content: '知谦科技入选湖南省科技厅小巨人企业',
      timestamp: '2018-04-03 20:46',
      color: '#0766E1',
    },

  ],
  timeFilter(list) {
    const year = []
    list.forEach(i => {
      const nowYear = i.timestamp.split(' ')[0]
      if (!year.includes(nowYear)) {
        year.push(nowYear)
      }
    })
    return year
  }
})
const introduce = ref({
  name: '关于知谦',
  img: '',
  content: ''
})
const yearList = ref([])
const allYearList = ref({
  zqTextImgMap: []
})

const activities = computed(() => {
  return allYearList.value.zqTextImgMap[yearTabs.value] || []
})
const dreamList = ref({
  name: '文化理念',
  zqTextImgList: []
})

const serviceList = ref({
  name: '服务能力',
  zqTextImgList: []
})

function changeYear(item) {
  yearTabs.value = item.name

}

const historyData = ref({})
getPageBaseAboutUsVo().then(res => {
  const newData = res.data
  introduce.value = newData.companyProfile
  dreamList.value = newData.culturalConcept
  serviceList.value = newData.serviceCapability
  yearList.value = Object.keys(newData.devHistory.zqTextImgMap)
  allYearList.value = newData.devHistory
  console.log(allYearList.value)

  // historyData.value = newData.devHistory

})


function timeFilter(list) {
  const year = []
  list.forEach(i => {
    const nowYear = i.timestamp.split(' ')[0]
    if (!year.includes(nowYear)) {
      year.push(nowYear)
    }
  })
  return year
}

</script>
<style lang='scss' scoped>
@keyframes imgScale {
  from {
    background-size: 110% 110%;
  }
  to {
    background-size: 100% 100%;
  }
}
.banner-img {
  width: 100%;
}
::v-deep .el-tabs__item {
  font-size: 18px;
}
.title {
  padding: 92px 0 65px;
  font-size: 42px;
  color: #202020;
  text-align: center;
}
.company-introduce {
  padding-bottom: 80px;
  background-color: #fff;
  .company-text {
    &::after {
      display: block;
      clear: both;
      content: "";
    }
    > img {
      float: left;
      margin-right: 50px;
      width: 50%;
    }
    > p {
      white-space: pre-wrap;
      padding: 15px 0;
      font-size: 16px;
      line-height: 1.8em;
      color: #222222;
    }
  }
}
.company-dream {
  margin: 50px 0 0;
  > ul {
    > li {
      padding: 53px 0 50px;
      width: 30%;
      background-color: #fff;
      border-radius: 6px;
      text-align: center;
      flex-wrap: wrap;
      > h3 {
        margin-bottom: 23px;
        width: 100%;
        font-size: 22px;
        color: #202020;
      }
      > p {
        font-size: 18px;
        color: #808080;
      }
    }
  }
}
.service-box {
  // background-color: #fff;
  > ul {
    display: flex;
    flex-wrap: wrap;
    width: 104%;
    > li {
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      margin: 0 2% 20px 0;
      box-sizing: border-box;
      padding: 54px 43px 0 35px;
      height: 180px;
      width: 48%;
      border-radius: 6px;
      background-color: #fff;
      &:hover {
        color: #fff;
        .service-img-box {
          .active-img {
            display: none;
          }

          .hide-img {
            display: block;
          }
        }
        background: url(../../assets/about/service-back.png) no-repeat;
        background-size: cover;
        animation: imgScale 2s;
      }
      .service-img-box {
        margin-right: 30px;
        flex-shrink: 0;
        width: 80px;
        > img {
          width: 80px;
        }
        .hide-img {
          display: none;
        }
      }
      .servide-info {
        flex: 1;
        > p {
          margin-top: 10px;
        }
      }
    }
  }
}
.business {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 311px;
  background: url("../../assets/about/consult-back.png") no-repeat;
  background-size: cover;
  > h3 {
    width: 100%;
    font-size: 36px;
    text-align: center;
    color: #fff;
  }
}
.history {
  padding-bottom: 50px;
  background-color: #fff;
  .year-list {
    position: relative;
    display: flex;
    margin-bottom: 40px;
    &::before {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #ccc;
      content: "";
    }
    > li {
      width: 100px;
      font-size: 18px;
      text-align: center;
      line-height: 2.5em;
      cursor: pointer;
      > div {
        border-bottom: 4px solid;
        border-color: transparent;
        display: inline-block;
      }
    }
    .on {
      > div {
        color: $hoverColor;
        border-color: $hoverColor;
      }
    }
  }
}
::v-deep .el-timeline-item__content {
  font-size: 18px;
}
::v-deep .el-timeline-item {
  padding-bottom: 30px;
}
</style>