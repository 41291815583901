import request from '@/utils/request'

// 获取关于我们页面数据
export function getPageBaseAboutUsVo(params) {
  return request({
    url: `/pageBase/getPageBaseAboutUsVo`,
    method: 'get',
    params,
  })
}

